@font-face {
    font-family: 'Product Sans';
    font-style: normal;
    font-weight: normal;
    src: url('ProductSans-Regular.ttf') format('truetype');

}

@font-face {
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: normal;
    src: url('Quicksand-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: normal;
    src: url('Quicksand-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 100;
    src: url('Quicksand-Light.ttf') format('truetype');
}

@font-face {
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 400;
    src: url('Quicksand-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 800;
    src: url('Quicksand-SemiBold.ttf') format('truetype');
}

@font-face {
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: bold;
    src: url('Quicksand-Bold.ttf') format('truetype');
}