@import './fonts/fonts.css';

body {
  font-family: 'Quicksand', sans-serif;
  margin: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
}

input, textarea, label, button {
  font-family: 'Quicksand', sans-serif;
}

.socialIcon {
  width: 25px;
  height: 25px;
}